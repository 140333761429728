import React from "react"
import styled from "@emotion/styled"
import facebook from "../images/facebook-logo.svg"
import instagram from "../images/instagram-logo.svg"
import twitter from "../images/twitter-logo.svg"

const SocialsContainer = styled.div`
  z-index: 110;
  position: fixed;
  bottom: 12px;
  right: 40px;
  width: 180px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .grow {
    transition: all 0.2s;
  }

  .grow:hover {
    transform: scale(1.2);
  }
`
const MentionLink = styled.a`
  font-size: 10px;
  color: #000;
  text-decoration: none;
`

const Socials = () => (
  <SocialsContainer>
    <MentionLink href="/mentions">Mentions Légales</MentionLink>
    <a
      href="https://www.instagram.com/harakiri.ramen/?hl=fr"
      target="_blank"
      rel="noopener noreferrer"
      className="grow"
    >
      <img src={instagram} alt="Aller sur Instagram"></img>
    </a>
    <a
      href="https://www.facebook.com/Hara-kiri-ramen-1212020148979412/"
      target="_blank"
      rel="noopener noreferrer"
      className="grow"
    >
      <img src={facebook} alt="Aller sur Facebook"></img>
    </a>
    <a
      href="https://twitter.com/harakiriramen"
      target="_blank"
      rel="noopener noreferrer"
      className="grow"
    >
      <img src={twitter} alt="Aller sur Twitter"></img>
    </a>
  </SocialsContainer>
)

export default Socials
