import React from "react"
import { Link } from "gatsby"
import hk from "../images/hk-logo.svg"
import "./css/smallHeader.css"

const SideMenu = props => (
  <>
    <div className="menu-small">
      <div className={props.isOpen ? "nav-toggle expanded" : "nav-toggle"}>
        <div className="nav-toggle-bar" onClick={props.toggle}></div>
      </div>
      <div className="smallLogo">
        <Link to="/" className="logo_anchor">
          <img src={hk} alt="Logo Hara Kiri Ramens"></img>
        </Link>
      </div>
    </div>
    <nav className={props.isOpen ? "nav expanded" : "nav"}>
      <ul>
        <li>
          <Link to="/entrées" className="smallA ripple">
            Entrées
          </Link>
        </li>
        <li>
          <Link to="/plats" className="smallA ripple">
            Plats
          </Link>
        </li>
        <li>
          <Link to="/desserts" className="smallA ripple">
            Desserts
          </Link>
        </li>
        <li>
          <Link to="/sakes" className="smallA ripple">
            Sakés
          </Link>
        </li>
        <li>
          <Link to="/restaurant" className="smallA ripple">
            À propos
          </Link>
        </li>
        <li>
          <Link to="/info" className="smallA ripple">
            Infos Pratiques
          </Link>
        </li>
        <li>
          <Link to="/blog" className="smallA ripple">
            Hara Kiri
          </Link>
        </li>
        <li>
          <a
            className="smallA ripple"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.ubereats.com/fr-FR/paris/food-delivery/hara-kiri-ramen/d0gdb8OUR9yfs-0cIBpHzw/"
          >
            Commander sur Uber Eats
          </a>
        </li>
        <li>
          <a
            className="smallA ripple"
            rel="noopener noreferrer"
            target="_blank"
            href="https://harakiriramen.byclickeat.fr/order"
          >
            Commander à emporter
          </a>
        </li>
        <li>
          <a
            className="smallA ripple"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.eshopharakiri.com/"
          >
            E-Shop
          </a>
        </li>
      </ul>
    </nav>
  </>
)

export default SideMenu
