import { Link } from "gatsby"
import styled from "@emotion/styled"
import React, { useState } from "react"
import CommandButton from "./CommandButton"
import SideMenu from "./SideMenu"
import hk from "../images/hk-logo.svg"
import infoImage from "../images/headerSakes.jpg"

const Header = props => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <SideMenu isOpen={isOpen} toggle={toggle} />
      <HeaderFixed>
        <HeaderContainer
          bgImage={props.bgImage}
          style={
            {
              // backgroundImage: props.bgImage ? url(${infoImage})" : "none",
            }
          }
        >
          <Left>
            <div className="dropdown">
              <Carte
                activeStyle={active}
                className="dropbtn"
                style={{
                  color: props.headerColor === "black" ? "black" : "white",
                }}
              >
                LA CARTE
              </Carte>
              <div className="separation"></div>
              <div className="dropdown-content draw">
                <Link to="/entrées">ENTRÉES</Link>
                <Link to="/plats">PLATS</Link>
                <Link to="/desserts">DESSERTS</Link>
              </div>
            </div>

            <StyledLink
              to="/sakes"
              activeStyle={active}
              style={{
                color: props.headerColor === "black" ? "black" : "white",
              }}
            >
              SAKÉS
            </StyledLink>

            <StyledLink
              to="/restaurant"
              activeStyle={active}
              style={{
                color: props.headerColor === "black" ? "black" : "white",
              }}
            >
              À PROPOS
            </StyledLink>

            <StyledLink
              to="/info"
              activeStyle={active}
              style={{
                color: props.headerColor === "black" ? "black" : "white",
              }}
            >
              INFOS PRATIQUES
            </StyledLink>

            <StyledLink
              to="/blog"
              activeStyle={active}
              style={{
                color: props.headerColor === "black" ? "black" : "white",
              }}
            >
              HARA KIRI
            </StyledLink>
            <StyledAnchor
              black={props.headerColor}
              target="_blank"
              href="https://www.eshopharakiri.com/"
            >
              E-SHOP
            </StyledAnchor>
          </Left>

          <Middle>
            <Link to="/#home">
              <img
                src={hk}
                className="hk-image"
                alt="Logo Restaurant Ramen Hara Kiri"
              ></img>
            </Link>
          </Middle>

          <CommandButton />
        </HeaderContainer>
      </HeaderFixed>
    </>
  )
}

const HeaderFixed = styled.header`
  background: transparent;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;

  @media screen and (max-width: 640px) {
    header {
      display: none;
    }

    #entrance {
      margin-top: 20px;
    }
  }
`

const HeaderContainer = styled.header`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  background-image: ${props =>
    props.bgImage ? `url(${infoImage})` : "transparent"};
  background-size: cover;
`

const StyledLink = styled(props => <Link {...props} />)`
  color: #fff;
  font-size: 13px;
  text-decoration: none;
  margin-right: 20px;
  font-weight: 600;
  transition: all 0.4s;
  position: relative;

  :hover {
    color: #e41408;
  }

  :after {
    content: "";
    position: absolute;
    height: 2px;
    background-color: #e41408;
    width: 0;
    left: 50%;
    bottom: -8px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
    transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
  }

  :hover:after {
    width: 100%;
  }
`

const StyledAnchor = styled.a`
  color: ${props => (props.black === "black" ? "black" : "white")};
  font-size: 13px;
  text-decoration: none;
  margin-right: 20px;
  font-weight: 600;
  transition: all 0.4s;
  position: relative;

  :hover {
    color: #e41408;
  }

  :after {
    content: "";
    position: absolute;
    height: 2px;
    background-color: #e41408;
    width: 0;
    left: 50%;
    bottom: -8px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
    transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
  }

  :hover:after {
    width: 100%;
  }
`

const Carte = styled.p`
  color: #fff;
  font-size: 13px;
  text-decoration: none;
  margin-right: 20px;
  font-weight: 600;
  cursor: default;
  transition: all 0.2s;
`

const Left = styled.div`
  margin-right: auto;

  .dropbtn {
    position: relative;
    transition: all 0.2s;
  }

  .dropbtn:after {
    content: "";
    position: absolute;
    height: 2px;
    background-color: #e41408;
    width: 0;
    left: 50%;
    bottom: -8px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
    transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
  }

  .dropbtn:hover:after {
    width: 100%;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    left: -22px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    min-width: 100px;
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    font-size: 13px;
    color: #fff;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: center;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    color: #e41408;
    /* background-color: #f1f1f1; */
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .separation {
    height: 10px;
  }
`

const Middle = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  .hk-image {
    transition: all 0.2s;
  }

  .hk-image:hover {
    transform: scale(1.2);
  }
`

const active = {
  color: "#e41408",
}

export default Header
