import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Socials from "./socials"
import "./css/layout.css"

const Layout = ({ children, headerColor, bgImage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        headerColor={headerColor}
        bgImage={bgImage}
      />
      <main>{children}</main>
      <Socials />
    </>
  )
}

export default Layout
