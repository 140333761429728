import React, { useState } from "react"
import styled from "@emotion/styled"
import uber from "../images/uber.png"
import pointer from "../images/pointer.png"
import takeaway from "../images/takeaway.svg"

const CommandButton = () => {
  const [isOpen, setIsOpen] = useState(false)

  function handleMouseOver() {
    setIsOpen(true)
  }

  function handleMouseOut() {
    setIsOpen(false)
  }

  return (
    <CommandContainer>
      <Right onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
        <CubeContainer isOpen={isOpen}>
          {/* <div className="cube flip-to-bottom"> */}
          <div className="default-state">
            <span>Commander</span>
          </div>
          <div className="active-state cont">
            <Pointer src={pointer} alt="Commander sur Uber Eat"></Pointer>
          </div>
          {/* </div> */}
        </CubeContainer>
        {/* </a> */}
        <SubMenu>
          {isOpen && (
            <DeliveryMenuContainer>
              <UberContainer>
                <a
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.ubereats.com/fr-FR/paris/food-delivery/hara-kiri-ramen/d0gdb8OUR9yfs-0cIBpHzw/"
                >
                  <img src={uber} alt="Commander sur Uber Eat"></img>
                </a>
              </UberContainer>
              <TakeAwayContainer>
                <a
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://harakiriramen.byclickeat.fr/order"
                >
                  <img src={takeaway} alt="Acheter à emporter"></img>
                </a>
              </TakeAwayContainer>
            </DeliveryMenuContainer>
          )}
        </SubMenu>
      </Right>
    </CommandContainer>
  )
}

const UberContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;

  img {
    width: 120px;
  }

  img:hover {
    transform: scale(0.9);
  }
`

const TakeAwayContainer = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  img {
    width: 120px;
  }

  img:hover {
    transform: scale(0.9);
  }
`

const CommandContainer = styled.div`
  position: absolute;
  right: 40px;
  top: 12px;
`

const Pointer = styled.img`
  width: 24px;
`

const CubeContainer = styled.div`
  transform: ${props => props.isOpen && `rotateX(89deg)`};
  width: 140px;
  height: 40px;
  transition: all 400ms ease;
  transform-style: preserve-3d;
  text-align: center;
  background-color: ${props => props.isOpen && `#fff`};
`

const Right = styled.div`
  margin-left: auto;
  position: relative;

  .cont {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  /* .cont img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  } */
  /* Container box to set the sides relative to */
  .cube {
    width: 140px;
    height: 40px;
    transition: all 400ms ease;
    transform-style: preserve-3d;
  }
  /* The two faces of the cube */
  .default-state,
  .active-state {
    height: 40px;
  }
  /* Position the faces */
  .default-state {
    transform: translateZ(20px);
  }
  .active-state {
    transform: rotateX(-90deg) translateZ(-20px);
  }
  /* Rotate the cube */

  /* .cube.flip-to-bottom:hover {
    transform: rotateX(89deg);
  } */

  .cube {
    text-align: center;
    margin: 0 auto;
  }
  .default-state,
  .active-state {
    background: #fff;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #e41408;
    line-height: 40px;
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
    transition: background 400ms ease;
  }
  .cube:hover .default-state {
    background: #fff;
  }
  .active-state {
    background: #fff;
  }
  #flipto {
    display: block;
    text-align: center;
    text-decoration: none;
    margin-top: 20px;
    color: #ccc;
  }
`

const SubMenu = styled.div``

const DeliveryMenuContainer = styled.div`
  height: 80px;
  width: 140px;
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
`
export default CommandButton
